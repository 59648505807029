<template>
  <div battle-acadmia-ranking>
    <OfficialLeagueGameInfoTemplate :title="title" @more-click="$router.push('/b/lol/record')">
      <div v-if="gameInfoShow">
        <div class="count-box">
          <CountView title="출전 대학" :length="3" :value="groupCount" :prxs="collCardPrx" />
          <CountView title="출전 팀" :length="3" :value="teamCount" :prxs="collCardPrx" />
        </div>
        <ToggleTab :tabs="['대학', '팀']" v-model="selectedIndex" />
        <div class="rank-holder">
          <div v-if="rankInfo.length > 0 && rankInfo[selectedIndex].myRank" class="my-team">
            <em class="label">내 {{ selectedIndex === 0 ? '대학교' : '팀' }}</em>
            <div v-if="rankInfo[selectedIndex].myRank" class="my-team-con">
              <em>{{ rankInfo[selectedIndex].myRank.rank }}</em>
              <ImageAsset url="/img/pages/icon/Rank_My.svg" bg-color="transparent" />
              <span>{{ rankInfo[selectedIndex].myRank.data.name }}</span>
            </div>
          </div>
          <ul v-if="rankInfo.length > 0" :class="['flags', { only: !rankInfo[0].myRank }]">
            <li
              v-for="(rank, index) in rankInfo[selectedIndex].ranks"
              :key="'rank-group-' + (rank.data.universityId || index)"
            >
              <ImageAsset :url="`/img/pages/icon/Rank_${index + 1}.svg`" bg-color="transparent" />
              <p>{{ rank.data.name }}</p>
            </li>
          </ul>
        </div>
      </div>
      <NoData v-else class="count-box" src="/img/asset/logo/ico-riot-gray.svg" text="<span>실시간 정보 집계 중입니다.</span>지금 리그에 도전하여 대학교 순위를 높여보세요!" />
    </OfficialLeagueGameInfoTemplate>
  </div>
</template>

<script>
import OfficialLeagueGameInfoTemplate from '@/views/components/gameHome/OfficialLeagueGameInfoTemplate.vue';
import CountView from '@/views/components/common/CountView.vue';
import ImageAsset from '@/views/components/common/ImageAsset.vue';
import ToggleTab from '@/views/components/common/tab/ToggleTab.vue';
import NoData from '@/views/components/common/NoData.vue';

export default {
  name: 'BattleAcademiaRanking',
  components: { OfficialLeagueGameInfoTemplate, CountView, ImageAsset, ToggleTab, NoData },
  data() {
    return {
      title: {
        title: 'RANKING',
        titleKo: '실시간 랭킹',
        subTitle: '실시간 정보는 1시간 단위로 업데이트 됩니다.',
        more: true,
      },
      collCardPrx: [
        { r: [4, 8], ty: [50, 0] },
        { r: [4.5, 9], ty: [-50, 0] },
        { r: [5, 10], ty: [50, 0] },
      ],
      selectedIndex: 0,
      groupCount: 0,
      teamCount: 0,
      rankInfo: [],
      gameInfoShow: false,
    };
  },
  methods: {
    async getGameInfo() {
      const res = this.$services.home.getAcademiaSummary();
      this.groupCount = res.groupCount;
      this.teamCount = res.teamCount;

      this.rankInfo[0] = res.groupRank;
      this.rankInfo[1] = res.teamRank;

      this.gameInfoShow = Boolean(res.groupRank.ranks.length + res.teamRank.ranks.length);
    },
  },
  mounted() {
    this.getGameInfo();
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[battle-acadmia-ranking] {
  .count-box {.w(100%); .mh-c; .bgc(#24262c); .br(8); .p(20, 0); .-box; .tc; .mt(32); .mb(40);
    [count-view]:nth-of-type(1) {.mr(48);}

    &[no-data] { .h(252); .p(10, 10); .bgc(rgba(36, 38, 44, 0.8)); border: 1px solid rgba(255, 255, 255, 0.1); .br(8);
      img { .max-w(100%); .w(107); }

      .text { .fs(14, 17); .c(rgba(255, 255, 255, 0.3));
        > span {.fs(16, 19.2); .c(rgba(255, 255, 255, 0.7)); .block; .m(28, 0, 8);}
      }
    }
  }
  .tab {.mb(37); .ib-list;
    button {.wh(140, 32); .br-t(8); .fs(14, 32px); .c(rgba(255, 255, 255, .5)); .bgc(#3d3f45); .rel;
      &.on {.bgc(white); .c(black);}
      &:nth-of-type(1) {.mr(8);}
      &:after {.cnt; width: calc(100% + 20px); .h(1); .bgc(#2f3033); .abs; .lb(0, 0);}
    }
  }
  .rank-holder { .crop;
    .my-team {.rel; .w(335); .p(16, 20); .pt(20); .-box; .br(4); .bgc(#24262c); .mh-c; .ib-list; .mb(32); .tc; border: 1px solid #5b91f5;
      .label {.abs; .lt(10, 0); z-index: 1; .p(4, 7); .fs(12); .c(#d4e2fc); .bgc(#5b91f5); .-a(rgba(212, 226, 252, 0.1)); .br-b(4); }
      .my-team-con { .rel;
        > em { .w(40); .abs; .lt(0, 8); z-index: 1; .fs(14); .tc; }
        > * {.vam;}
      }
      [image-asset] {.wh(40, 50); .br(0);
        img {.h(100%); .w;}
      }
      span {.fs(13); .ml(16); .c(rgba(255, 255, 255, .8));
        em {.ml(6);}
      }
    }
    .flags { .ib-list; .w(335); .mh-c; .tc; .clear;
      &.only { .fl; }
      li { .wh(100, 133); .bgc(#24262c); .tc; .br(4); .rel;
        [image-asset] {.wh(40, 51); .br(4); .mt(24);
          & + p {.fs(13); .c(rgba(255, 255, 255, .8)); .mt(16); .p(0, 5);}
        }
        &:before {.wh(100%, 4); .cnt; .abs; .lt(0, 0); .br-t(4); .bgc(#e3cb86); z-index: 1;}
        // &:after{.cnt; .wh(40,50); .abs; .lt(8,-8); .contain('@{icon}/Rank_1.svg'); z-index: 2;}

        &:nth-of-type(1) {.fl;}
        &:nth-of-type(2) {
          &:before {.bgc(#a1bdca);}
          &:after {.contain('@{icon}/Rank_2.svg');}
        }
        &:nth-of-type(3) {.fr;
          &:before {.bgc(#dd6e49);}
          &:after {.contain('@{icon}/Rank_3.svg');}
        }
      }
    }
  }

  @media (@tl-up) {
    .count-box {.w(100%); .br(8); .p(24, 0); .mt(28); .mb(75);
      [count-view]:nth-of-type(1) {.mr(48);}

      &[no-data] { .h(300); }
    }
    .tab { .mb(42);
      button {.wh(200, 48); .fs(16, 48px);
        &:after {width: calc(100% + 132px);}
      }
    }
    .rank-holder { .clear; .w(100%); .mh-c;
      .my-team {.ib; .wh(60%, 133); .mb(0); .tc; .pt(30); .rel;
        .my-team-con {
          > em { .w(60); .lt(0, 15); .fs(16); }
        }
        [image-asset] {.wh(60, 76); .br(0);}
        &:after { .cnt; .wh(1, 100%); .bgc(#2f3033); .rb(-10%, 0); .abs; .hide;}
      }
      .flags {.ib; .fr;}
    }

  }

  @media (@tp-up) and (@tl-down) {
    .rank-holder {.t-y(0);.o(1);.flex;justify-content: space-between;
      .my-team {.wh(50%, 133);.pt(28);.ml(0);.ib;.tc;
        .my-team-con {
          > em { .w(60); .lt(0, 15); .fs(16); }
        }
        [image-asset] {.wh(60, 76); .br(0);}
      }
      .flags {.ib;.fr;}
    }
  }

  @media (@ds-up) {
    .count-box { .w(542); .mt(28); .mb(88); .ml(0);}
    .tab {.mb(54);}
    .rank-holder { .w(100%);
      .my-team { .wh(160, 167); .br(8); .pt(35);
        .my-team-con {
          > em { .w(100%); .lt(0, 15); .fs(16); }
          > span {.ib;}
        }
        [image-asset] {.wh(60, 76); .mh-c; .block; .br(0);}
        span {white-space: nowrap; .tl; .ml(0); .mt(16);}
        &:after {.h(132); .r(-22);}

        .flags {.mt(34);}
      }
    }

    .count-box {
      &[no-data] { .h(530);
        img { .w(135); }
        .text { .fs(14);
          > span {.fs(20, 24); .mt(40); .mb(8);}
        }
      }
    }
  }
}
</style>
